import { ReactElement } from "react";

export const defaultDateFormat = "M/d/yyyy";

export enum FileType {
  Logo = "logo",
  ProfilePicture = "profile_picture",
  ACRA = "acra",
  PitchDeck = "pitch",
  SummaryAndOther = "summary_and_other",
  ProductPhoto = "product_photo",
  DealDeck = "deal_deck",
  ExecutiveSummary = "executive_summary",
  Other = "other",
}

export interface InnovatorFile {
  created: string;
  id: number;
  publicUrl: string;
  secureUrl: string;
  type: FileType;
  productId: number;
  companyId: number;
  displayOrder?: number;
  signedUrl?: string;
}

export interface Diligence {
  updated: string;
  lastUpdatedBy: User;
  raisingAmount: string;
  roundOpen: string;
  stockOrNote: string;
  isRoundTranched: string;
  leadInvestor: string;
  significantInvestors: string;
  amountNeededToClose: string;
  priorConvertibleNote: string;
  anticipatedClosing: string;
  unmetNeed: string;
  productCapabilities: string;
  teamExpertise: string;
  manufacturingPlan: string;
  manufacturingPartnersAndSupplierAgreements: string;
  COGSAndMargin: string;
  businessModel: string[];
  launchTiming: string;
  pricingStrategyAndAssumptions: string;
  haveCustomers: string;
  distributorAgreementsAndSalesChannels: string;
  generatingRevenueYesNo: string;
  servicesRevenue: string;
  secondReleasePlan: string;
  overallStrength: string;
  execution: string;
  traction: string;
  notableAwards: string;
  keyAccomplishments: string;
  productDeliveryProof: string;
  academicIndustryPartners: string;
  patentsToFile: string;
  patentsToFileDescription: string;
  patentsFiled: string;
  patentsFiledDescription: string;
  patentsIssued: string;
  patentsIssuedDescription: string;
  ftoAnalysis: string;
  summary: string;
  fdaPreSubmissionMeetingDate: string;
  fdaSubmissionDate: string;
  fdaApprovalDate: string;
  ceMarkSubmissionDate: string;
  regulatoryApprovalStatus: string;
  businessModelDetail: string;
  submitted: string;
}

export enum RoleOptions {
  Admin = "admin",
  Company = "company",
  Investor = "investor",
  Partner = "partner",
  Ecosystem = "ecosystem",
}

export interface Note {
  id: number;
  companyId: number;
  note: string;
  created: string;
  updated: string;
  lastUpdatedBy: User;
}

export interface ProgramApplicationSubmissionData {
  companyName: string;
  productName: string;
  oneLineDescription: string;
  diversityInitiativesStatus: string[];
  minoritySelect: string[];
  roundsClosed: string[];
  existingEquityInvestorTypes: string[];
  milestonesAccomplished: string[];
  milestonesNewFunding: string[];
  primaryRevenueModel: string[];
  customerSelect: string[];
  patientJourney: string[];
  productCategories: string[];
  deviceCategories: string[];
  diagnosticCategories: string[];
  digitalCategories: string[];
  clinicalCategories: string[];
  themeCategories: string[];
  specialApplicationCategories: string[];
  applicationCategories: string[];
  biotoolsTechnologyCategories: string[];
  femaleSelect: string[];
  externalFundingHistory: string[];
  [key: string]: string | ReactElement | any[];
  ceoInfo: CEODetails | any;
}

export interface CEODetails {
  name: string;
  title: string;
  email: string;
  linkedInUrl: string;
  profileImageUrl: string;
}

export interface ProgramApplicationSubmission {
  id: number;
  name: string;
  title: string;
  description: string;
  status?: string;
  submissionId?: string;
  slug: string;
  waitlistUrl?: string;
  programApplicationId: number;
  companyId: string;
  company: Company;
  submissionData: ProgramApplicationSubmissionData;
  files: any;
  submittedDate: string;
  programApplication: ProgramApplication;
  bookmarked: boolean;
  deletedAt: Date;
  isDeleted: string;
  curated: string[];
  lists: string[];
  exclude: string[];
  companyAssigned: boolean;
  submittedBy: User;
  assignedNames: string[];
  reviewStatus: string;
  programApplicationSubmissionLists: ProgramApplicationSubmissionList[];
  ceoInfo?: CEODetails;
}

export enum ProgramApplicationStatus {
  ACTIVE = "ACTIVE",
  WAITLIST = "WAITLIST",
  CLOSED = "CLOSED",
}

export interface ProgramApplication {
  id: number;
  slug: string;
  surveyJSForm: any;
  title: ProgramApplicationTitles;
  canEditAfterSubmission: boolean;
  programApplicationSubmissions: ProgramApplicationSubmission[];
}

export enum ApplicationSubmissionStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
  PENDING_REVISION = "PENDING_REVISION",
  NOT_STARTED = "NOT_STARTED",
}

export interface ApplicationSubmission {
  id: number;
  status: ApplicationSubmissionStatus;
  slug: string;
  label: string;
  programApplication: ProgramApplicationSubmission;
  programApplicationId: number;
}

export interface User {
  role: RoleOptions;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  city: string;
  state: string;
  zipcode: string;
  updated: string;
  country: string;
  id: number;
  companyId: number;
  profileImageUrl: string;
  linkedInUrl: string;
  phoneNumber: string;
  shortBio: string;
  headline: string;
  slackId: string;
  openRoundsAccess: boolean;
  emailVerified: boolean;
  invalidEmail: boolean;
  company: Company;
  isFormerEmployee: boolean;
  displayOrder?: number;
  tags: UserTags[];
  isCompanyAdmin: boolean;
  programs?: string[];
  reviewerConfiguration?: ReviewerConfiguration;
  type?: TypeOptions;
  lastLogin: string;
  created: string;
  expertiseExplanation: string;
  proxyCurlData: object;
}

export interface ActivityItem {
  id: number;
  type: string;
  date: string;
  dateTime: string;
  comment?: string;
}

export enum CohortMembership {
  mti2013 = "2013",
  mti2014 = "2014",
  mti2015 = "2015",
  mti2016 = "2016",
  mti2017 = "2017",
  mti2018 = "2018",
  mti2019 = "2019",
  mti2020 = "2020",
  mti2021 = "2021",
  mti2022 = "2022",
  mti2023 = "2023",
  mti2024 = "2024",
  bti2021 = "biotools2021",
  bti2022 = "biotools2022",
  bti2023 = "biotools2023",
  bti2024 = "biotools2024",
  apac2019 = "apac2019",
  apac2020 = "apac2020",
  apac2021 = "apac2021",
  apac2022 = "apac2022",
  apac2023 = "apac2023",
  apac2024 = "apac2024",
  peds2019 = "peds2019",
  peds2020 = "peds2020",
  peds2021 = "peds2021",
  peds2022 = "peds2022",
  peds2023 = "peds2023",
  usMarketAccess2024 = "usMarketAccess2024",
}

export enum FeedbackLevel {
  Weak = "Weak",
  Good = "Good",
  Strong = "Strong",
}

export enum Recommendation {
  Yes = "Yes",
  No = "No",
}

export interface Company {
  abbreviation: string;
  acceleratorIncubator?: string;
  ceoEmail: string;
  ceoFirstName: string;
  ceoLastName: string;
  ceoMobile?: string;
  city: string;
  cleanName: string;
  closeDate?: string;
  cohortMembership: string[];
  companyLists: any;
  competitions?: string;
  competitors: string;
  country: string;
  created: string;
  debtConvertibleNote: string;
  deletedAt: string;
  developmentStage: string;
  diversityInitiativesStatus: string[];
  elevatorPitch: string;
  equity: string;
  equityToDate: number;
  existingEquityInvestorTypes?: string[];
  existingNonEquityGrants?: string[];
  femaleExecutives?: number;
  femaleSelect?: string[];
  formation?: string;
  fullTimeHeadcount: number;
  fundingContact: User;
  fundingContactId?: number;
  fundingSeeking?: number;
  fundingToDate: number;
  hubSpotRecordId: string;
  id: number;
  isReviewer: boolean;
  isMTISponsor: boolean;
  isBTISponsor: boolean;
  isAPACSponsor: boolean;
  lastUpdatedBy: User;
  linkedInUrl: string;
  location: string;
  logoUrl: string;
  majorPublications?: string;
  marketAdvantage: string;
  milestones: Milestone[];
  minoritySelect?: string[];
  mostRecentApplicationId: number;
  mostRecentEquityRoundClosingDate?: string;
  mostRecentEquityRoundTotal?: number;
  name: string;
  nonDilutiveFunding: string;
  openNextRound?: string;
  partTimeHeadcount: number;
  patientsImpacted: number;
  phone: string;
  previouslyApplied?: string;
  primaryProduct: Product;
  primaryProductId: number;
  primaryUserId: number;
  programAccess: string;
  programSponsor: string;
  programVoting: string;
  regulatoryPathway: string;
  roundsClosed?: string[];
  runway: string;
  shareDealsWithInvestors: boolean;
  shareDealsWithPortfolio: boolean;
  significantPartners?: string;
  significantSourcesOfExistingFunding?: string;
  state: string;
  street1: string;
  street2: string;
  teamAndExpertise?: string;
  totalEquityFunding?: number;
  totalNonDilutiveFunding?: number;
  typeAbbreviation: string;
  underrepresentedMinority?: number;
  updated: string;
  users: User[];
  website: string;
  yearFounded: number;
  zipcode: string;
  programApplicationSubmissions: ProgramApplicationSubmission[];
  canAssignApplicationsToColleagues?: boolean;
  notes: Note[];
  type: TypeOptions;
}

export interface Application {
  id: number;
  name: string;
  created: string;
  updated: string;
  deletedAt: string;
  teamExpertise: string;
  summary: string;
  investorTypesSelect: string;
  projectAbstract: string;
  competitors: string;
  significantPartnerships: string;
  significantInvestors: string;
  marketAccessPlan: string;
  programYearProjectedRevenue: string;
  anticipatedClosing: string;
  milestonesAchievable: string;
  milestonesAccomplished: string;
  proceeds: string;
  generatingRevenueYesNo: string;
  revenueModelSelect: string;
  medtechCategory: string;
  need: string;
  healthcareEconomics: string;
  seeking: string;
  fundingSeriesSelect: string;
  grantsToDate: string;
  equityToDate: string;
  competitionsList: string;
  notableAwards: string;
  keyAccomplishments: string;
  medicalAreaPrimary: string;
  runway: string;
  regulatoryPathway: string;
  regulatoryStatus: string;
  commercialUptake: string;
  marketSize: string;
  marketAdvantage: string;
  valueProposition: string;
  patientJourney: string;
  productOverallCategories: string;
  themeCategories: string;
  otherCategory: string;
  otherTechnology: string;
  otherSeeking: string;
  teamFormation: string;
  teamFulltime: string;
  diversitySelect: string;
  diversityEquityAgenda: string;
  howHeard: string;
  howHeardDetail: string;
  partner: string;
  delegate: string;
  specialCategory: string;
  diagnosticCategories: string;
  digitalCategoryPrimary: string;
  deviceCategoryPrimary: string;
  diagnosticCategoryPrimary: string;
  developmentStage: string;
  classOfDevice: string;
  indication: string;
  productPhoto: string;
  pitchVideo: string;
  productVideoUrl: string;
  appVisibility: string;
  appType: string;
  completeDatetime: string;
  withdrawn: string;
  previouslyApplied: string;
  otherApplication: string;
  feedbackApproved: string;
  updateLog: string;
  updates: string;
  folderid: string;
  filelist: string;
  fto: string;
  nih: string;
  nihGrantNumber: string;
  smta: string;
  ipStatus: string;
  patentsFiled: string;
  patentsFiledDescription: string;
  patentsIssued: string;
  patentsIssuedDescription: string;
  patentsToFile: string;
  patentsToFileDescription: string;
  fdaPreSubmissionMeetingDate: string;
  fdaSubmissionDate: string;
  fdaApprovalDate: string;
  ceMarkSubmissionDate: string;
  asiaRegulatoryStatus: string;
  asiaRegulatoryDetails: string;
  euroRegulatoryStatus: string;
  currentCustomerSelect: string;
  customerPrimary: string;
  lastFundingAmount: string;
  lastFundingDate: string;
  mostRecentEquityRoundClosed: string;
  priorRoundsYesNo: string;
  roundRaising: string;
  lastUpdatedBy: User;
  createdBy: User;
  company: Company;
  competition: string;
  pitchEvent: string;
  pitchEventAlternatives: string;
  koboId: string;
  shortDescription: string;
  mtiShort: string;
  clinicalCategories: string;
  digitalCategory: string;
  biotoolsCategory: string;
  biotoolsTechnology: string;
  biotoolsAppCategory: string;
  companyId: number;
  acceleratorsAndIncubators: string;
  countryPatentFiledGrantedIfNotUs: string;
  diversity: string;
  diversityEquity: string;
  equity: string;
  equityRaised: string;
  equityRaisedToDate: string;
  equityRaisedInLastRound: string;
}

export enum VideoType {
  // Competition = "competition",
  // Evidence = "evidence",
  // InfoSession = "infoSession",
  // Interview = "interview",
  Lightning = "lightning",
  Marketing = "marketing",
  Pitch = "pitch",
  // Testimonial = "testimonial",
  // Webinar = "webinar",
  // Other = "other",
}

export const VideoTypeOptions = [
  { value: VideoType.Lightning, label: "Lightning Pitch" },
  { value: VideoType.Marketing, label: "Marketing" },
  { value: VideoType.Pitch, label: "Pitch Event" },
];

export const NewVideoTypeOptions = [
  { value: VideoType.Marketing, label: "Marketing" },
];

export interface Video {
  id: number;
  url: string;
  youtubeVideoId: string;
  created: string;
  updated: string;
  deletedAt?: string;
  title: string;
  description: string;
  companyId?: number | null;
  companyName?: string | null;
  duration?: number;
  viewCount?: number;
  type?: VideoType | null;
  lastUpdatedBy?: User;
  company: Company;
  productId?: number | null;
  isVisible: boolean | null;
}

export enum NotificationFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  None = "None",
}

export enum AlertContent {
  NewDeal = "New deal matching your filter criteria",
  ChangesToDealAmountOrValuations = "Deal amount changed",
  ChangesToProjectedCloseDate = "Changes to close date",
  DealStatusChanged = "Deal status changed (completed/failed)",
  MilestonesAddedOrUpdated = "Milestones added or updated",
}

export interface Milestone {
  id?: number;
  created?: string;
  updated: string;
  deletedAt?: string;
  name: string;
  startDate?: string;
  completedDate?: string;
  companyId?: number;
  productId?: number;
  status?: string;
  funding?: milestoneFunding;
  category?: milestoneCategories;
  isFunding?: boolean;
  isHighlight?: boolean;
  expanded?: boolean;
  hasTimeDuration?: boolean;
  date?: string;
  displayDate?: string;
  detail?: string;
  lastUpdatedBy?: User;
  product?: Product;
}

export interface Investor {
  id: number;
  created: string;
  updated: string;
  deletedAt: string | null;
  name: string;
}

export interface DealToInvestor {
  created: string;
  updated: string;
  deletedAt: string | null;
  dealId: number;
  investorId: number;
  isLead: boolean;
  investor: Investor;
}

export enum dealFundingStatus {
  inProgress = "In Progress",
  completed = "Completed",
  failedCancelled = "Failed/Cancelled",
}

export interface Deal {
  id: number;
  updated: string;
  lastUpdatedById: string;
  lastUpdatedBy: User;
  companyId: number;
  airTableId: string;
  amount: number;
  openAmount: number;
  closedAmount: number;
  committedAmount: number;
  amountStatus: string;
  clinicalCategories: any[];
  createdTime: string;
  closeDate: string;
  type: DealTypes;
  round: string;
  deviceCategories: any[];
  equityPostMTI: number;
  fundingStatus?: dealFundingStatus;
  leadClinicalCategory: any[];
  location: string;
  programYear: string;
  postVal: number;
  preVal: number;
  pps: number;
  productDevelopmentStage: string;
  program: string;
  regulatoryPathway: string;
  source: string;
  public: boolean;
  company: Company;
  dealToInvestors: DealToInvestor[];
  isPristine: boolean;
  hasTermSheet: boolean;
  hasLead: boolean;
  isPrivate?: boolean;
  isPublished: boolean;
  highlight?: string;
  urgency?: string;
  usRegulatoryStatus?: string;
  projectedRunway?: string;
  runway?: string;
  previousInvestors: string[];
  watchListed?: boolean;

  isNew?: boolean;
  //Risk Filter placeholders:
  revenue: string;
  regulatoryStatus: string;
  evidenceGatheringStatus: string;
  team: string;
  finalFundraiser: boolean;
}

export const blankDeal = {
  id: -1,
  updated: "",
  lastUpdatedById: "",
  previousInvestors: [],
  preVal: null,
  postVal: null,
  amount: null,
  openAmount: null,
  closedAmount: null,
  committedAmount: null,
  companyId: 0,
  date: "",
  dealToInvestors: [],
  public: false,
  company: {} as Company,
  isPristine: true,
  airTableId: "",
  amountStatus: "",
  clinicalCategories: [],
  createdTime: "",
  deviceCategories: [],
  equityPostMTI: 0,
  leadClinicalCategory: [],
  location: "",
  programYear: "",
  pps: 0,
  productDevelopmentStage: "",
  program: "",
  regulatoryPathway: "",
  source: "",
  lastUpdatedBy: {} as User,
  isPrivate: false,
  isPublished: false,
  hasTermSheet: false,
  hasLead: false,
  round: "",
  revenue: "",
  regulatoryStatus: "",
  evidenceGatheringStatus: "",
  team: "",
  finalFundraiser: false,
  investors: [],
};

export interface FilterPill {
  category: string;
  name: string;
  numberOptions: number;
  numberSelectedOptions: number;
}

export interface InvestorFilter {
  id: number;
  name: string;
  description: string;
  hasLead: string;
  productDevelopmentStage: string[];
  regulatoryPathway: string[];
  regulatoryStatus: string[];
  alertContents: AlertContent[];
  notificationFrequency: NotificationFrequency;
  selectedListId: string[];
  searchTerm: string;
  clinicalAreas: string[];
  dealType: string[];
  amountMin: string;
  amountMax: string;
  type: string[];
  alerts: boolean;
  watchListed: boolean;
  emailRecipients: string[];
  startDate: string;
  endDate: string;
  round: string[];
}

export enum milestoneStatuses {
  completed = "Completed",
  inProgress = "In-Progress",
  upcoming = "Upcoming",
}

export const milestoneStatusOptions = [
  { value: milestoneStatuses.completed, label: "Completed" },
  { value: milestoneStatuses.inProgress, label: "In-Progress" },
  { value: milestoneStatuses.upcoming, label: "Upcoming" },
];

export enum milestoneFunding {
  Completed = "completed",
  Current = "current",
  OpenRound = "openRound",
  FutureRound = "futureRound",
}

export const milestoneFundingOptions = [
  {
    value: milestoneFunding.Completed,
    label: "Milestones already accomplished",
  },
  {
    value: milestoneFunding.Current,
    label: "Milestones achievable with your current funding / resources",
  },
  {
    value: milestoneFunding.OpenRound,
    label: "Milestones that your open round will achieve",
  },
  {
    value: milestoneFunding.FutureRound,
    label: "Milestones that a future round will achieve",
  },
];

export enum milestoneCategories {
  Clinical = "clinical",
  EvidenceGeneration = "evidence",
  Financial = "financial",
  ProductDevelopment = "product",
  Regulatory = "regulatory",
  ReimbursementPayment = "reimbursement",
  Traction = "traction",
}

export const milestoneCategoriesOptions = [
  { value: milestoneCategories.Clinical, label: "Clinical" },
  {
    value: milestoneCategories.EvidenceGeneration,
    label: "Evidence Generation (Pre-clinical and Clinical)",
  },
  { value: milestoneCategories.Financial, label: "Financial" },
  { value: milestoneCategories.Regulatory, label: "Regulatory" },
  {
    value: milestoneCategories.ProductDevelopment,
    label: "Product Development",
  },
  {
    value: milestoneCategories.ReimbursementPayment,
    label: "Reimbursement / Payment",
  },
  {
    value: milestoneCategories.Traction,
    label: "Traction (# Patients, # Procedures, # Customers)",
  },
];

export enum ProductDevelopmentStage {
  concept = "Concept",
  marketReady = "Market Ready",
  payingCustomers = "Paying Customers",
  productDeveloped = "Product Developed",
  productDevelopedBetaTesting = "Product Developed - Beta Testing",
  productDevelopedPreApproval = "Product Developed - Pre-Approval",
  prototypeDeveloped = "Prototype Developed",
  prototypeDevelopedClinical = "Prototype Developed - Clinical",
  prototypeDevelopedPreClinical = "Prototype Developed - Pre-Clinical",
}

export enum ProductUsRegulatoryStatus {
  applicationFiledUs = "Application Filed",
  fdaApproved = "FDA Approved",
  fdaPreSubmissionMeetingCompleted = "Had FDA Pre-Submission Meeting",
  notApplicable = "Not Applicable",
  notStarted = "Not Started",
  preparingForSubmission = "Preparing for Submission",
  preparingSubmissionUs = "Preparing Submission",
  scheduledFdaPreSubmissionMeeting = "Scheduled FDA Pre-Submission Meeting",
}

export const ProductUsRegulatoryStatusOptions = [
  {
    value: ProductUsRegulatoryStatus.notStarted,
    label: "Not Started",
  },
  {
    value: ProductUsRegulatoryStatus.notApplicable,
    label: "Not Applicable",
  },
  {
    value: ProductUsRegulatoryStatus.scheduledFdaPreSubmissionMeeting,
    label: "Scheduled FDA Pre-Submission Meeting",
  },
  {
    value: ProductUsRegulatoryStatus.fdaPreSubmissionMeetingCompleted,
    label: "Had FDA Pre-Submission Meeting",
  },
  {
    value: ProductUsRegulatoryStatus.preparingSubmissionUs,
    label: "Preparing Submission",
  },
  {
    value: ProductUsRegulatoryStatus.applicationFiledUs,
    label: "Application Filed",
  },
  {
    value: ProductUsRegulatoryStatus.fdaApproved,
    label: "FDA Approved",
  },
];

export enum TypeOptions {
  Manufacturing = "Manufacturing",
  VentureCapital = "Venture Capital",
  ServiceProvider = "Service Provider",
  IntellectualProperty = "Intellectual Property",
  Regulatory = "Regulatory",
  Reimbursement = "Reimbursement",
  AngelInvestor = "Angel Investor",
  Payer = "Payer",
  Provider = "Provider",
  KeyOpinionLeader = "Key Opinion Leader",
  University = "University/Academic",
  Government = "Government",
  Incubator = "Incubator/Accelerator",
  SpecialtyOrganization = "Specialty Organization",
  SubjectMatterExpert = "Subject Matter Expert",
}

export enum ProductUsRegulatoryPathway {
  fiveHundredTenK = "510(k)",
  deNovo = "De Novo",
  pma = "PMA",
  combinationProductCDRH = "Combination Product (CDRH)",
  combinationProductCBER = "Combination Product (CBER)",
}

export enum ProductEURegulatoryStatus {
  notStartedOrApplicable = "Not Started or Applicable",
  preparingSubmissionEu = "Preparing Submission",
  applicationFiledEu = "Application Filed",
  ceMarked = "CE Marked",
}

export enum ProductAsiaRegulatoryStatus {
  preparingSubmissionAsia = "Preparing Submission",
  applicationFiledAsia = "Application Filed",
  registeredAsia = "Registered",
}

export const ProductEURegulatoryStatusOptions = [
  {
    value: ProductEURegulatoryStatus.notStartedOrApplicable,
    label: "Not Started or Applicable",
  },
  {
    value: ProductEURegulatoryStatus.preparingSubmissionEu,
    label: "Preparing Submission",
  },
  {
    value: ProductEURegulatoryStatus.applicationFiledEu,
    label: "Application Filed",
  },
  {
    value: ProductEURegulatoryStatus.ceMarked,
    label: "CE Marked",
  },
];

export interface Product {
  id: number;
  name: string;
  summary: string;
  elevatorPitch: string;
  developmentStage: ProductDevelopmentStage | string | null;
  files: InnovatorFile[];
  regulatoryPathway: string;
  usRegulatoryStatus: string;
  europeRegulatoryStatus: string;
  asiaRegulatoryStatus: string;
  asiaRegulatoryDetails: string;
  isPrimaryProduct: boolean;
  companyId: number;
  productCategories: string[];
  clinicalCategories: string[];
  primaryClinicalCategory?: string;
  updated: string;
  fdaClassification?: string;
  breakthroughDesignation?: string;
  lastUpdatedBy: User;
  primaryBiotoolsTechnologyCategory: string;
  biotoolsTechnologyCategories: string[];
  applicationCategories: string[];
  valueProposition: string;
  businessModel: string;
  needOrProblem: string;
  goToMarketStrategy: string;
  marketSize: string;
  intellectualPropertyStatus: string;
  freedomToOperate: string;
  videoUrl: string;
}

export const emptyPortalProduct: Product = {
  id: 0,
  name: "",
  summary: "",
  elevatorPitch: "",
  developmentStage: null,
  files: [],
  regulatoryPathway: "",
  usRegulatoryStatus: "",
  europeRegulatoryStatus: "",
  asiaRegulatoryStatus: "",
  asiaRegulatoryDetails: "",
  isPrimaryProduct: false,
  companyId: 0,
  productCategories: [],
  clinicalCategories: [],
  primaryClinicalCategory: "",
  updated: "",
  fdaClassification: "",
  breakthroughDesignation: "",
  lastUpdatedBy: {} as User,
  primaryBiotoolsTechnologyCategory: "",
  biotoolsTechnologyCategories: [],
  applicationCategories: [],
  valueProposition: "",
  businessModel: "",
  needOrProblem: "",
  goToMarketStrategy: "",
  marketSize: "",
  intellectualPropertyStatus: "",
  freedomToOperate: "",
  videoUrl: "",
};

export const productCategoryOptions = [
  { value: "Diagnostic", label: "Diagnostic" },
  { value: "Digital", label: "Digital Health" },
  { value: "Device", label: "Medical Device" },
  { value: "Equipment / Instruments", label: "Equipment / Instruments" },
  { value: "Services", label: "Services" },
  { value: "Reagents / Consumables", label: "Reagents / Consumables" },
  { value: "Software", label: "Software" },
];

export const mtiProductCategoryOptions = [
  { value: "Diagnostic", label: "Diagnostic" },
  { value: "Digital", label: "Digital Health" },
  { value: "Device", label: "Medical Device" },
];

export const btiProductCategoryOptions = [
  { value: "Equipment / Instruments", label: "Equipment / Instruments" },
  { value: "Services", label: "Services" },
  { value: "Reagents / Consumables", label: "Reagents / Consumables" },
  { value: "Software", label: "Software" },
];

export const clinicalCategoryOptions = [
  { value: "Allergy / Immunology", label: "Allergy / Immunology" },
  {
    value: "Anesthesiology / Critical Care Medicine",
    label: "Anesthesiology / Critical Care Medicine",
  },
  {
    value: "Cardiac / Thoracic / Vascular Surgery",
    label: "Cardiac / Thoracic / Vascular Surgery",
  },
  { value: "Cardiology", label: "Cardiology" },
  { value: "Colo / Rectal Surgery", label: "Colo / Rectal Surgery" },
  {
    value: "Dentistry / Orthodontics / Oral Surgery",
    label: "Dentistry / Orthodontics / Oral Surgery",
  },
  { value: "Dermatology", label: "Dermatology" },
  { value: "Emergency Medicine", label: "Emergency Medicine" },
  {
    value: "Endocrinology / Diabetes / Metabolism",
    label: "Endocrinology / Diabetes / Metabolism",
  },
  { value: "ENT", label: "ENT" },
  { value: "Gastroenterology", label: "Gastroenterology" },
  { value: "General Surgery", label: "General Surgery" },
  { value: "Infectious Disease", label: "Infectious Disease" },
  { value: "Interventional", label: "Interventional" },
  { value: "Nephrology", label: "Nephrology" },
  { value: "Neurological Surgery", label: "Neurological Surgery" },
  { value: "Neurology", label: "Neurology" },
  { value: "Obstetrics / Gynecology", label: "Obstetrics / Gynecology" },
  { value: "Oncology / Hematology", label: "Oncology / Hematology" },
  { value: "Ophthalmology", label: "Ophthalmology" },
  {
    value: "Orthopaedic Surgery / Sports Medicine",
    label: "Orthopaedic Surgery / Sports Medicine",
  },
  { value: "Pain Medicine", label: "Pain Medicine" },
  {
    value: "Pathology / Laboratory Medicine",
    label: "Pathology / Laboratory Medicine",
  },
  {
    value: "Physical Medicine / Rehabilitation",
    label: "Physical Medicine / Rehabilitation",
  },
  {
    value: "Plastic Surgery / Aesthetics",
    label: "Plastic Surgery / Aesthetics",
  },
  {
    value: "Preventive Medicine / Wellness",
    label: "Preventive Medicine / Wellness",
  },
  {
    value: "Psychiatry / Behavioral Health",
    label: "Psychiatry / Behavioral Health",
  },
  { value: "Pulmonary Disease", label: "Pulmonary Disease" },
  {
    value: "Radiology / Nuclear Medicine Rad Onc",
    label: "Radiology / Nuclear Medicine Rad Onc",
  },
  { value: "Rheumatology", label: "Rheumatology" },
  { value: "Sleep Medicine", label: "Sleep Medicine" },
  { value: "Urology", label: "Urology" },
];

export const biotoolsTechnologyCategoryOptions = [
  {
    value: "Analytical Technologies (Flow cytometry, Chromatography, MS, etc.)",
    label: "Analytical Technologies (Flow cytometry, Chromatography, MS, etc.)",
  },
  { value: "Cell Analysis and Imaging", label: "Cell Analysis and Imaging" },
  { value: "Cell Biology Technologies", label: "Cell Biology Technologies" },
  {
    value:
      "Cell Engineering Technologies (including Gene Delivery Technologies)",
    label:
      "Cell Engineering Technologies (including Gene Delivery Technologies)",
  },
  { value: "Genomic Technologies", label: "Genomic Technologies" },
  {
    value: "Lab Automation and Robotics",
    label: "Lab Automation and Robotics",
  },
  {
    value: "Molecular Biology Reagents and Equipment",
    label: "Molecular Biology Reagents and Equipment",
  },
  { value: "Multi-omics Technologies", label: "Multi-omics Technologies" },
  { value: "Proteomic Technologies", label: "Proteomic Technologies" },
  {
    value: "Sample Preparation Technologies",
    label: "Sample Preparation Technologies",
  },
  { value: "Sequencing Technologies", label: "Sequencing Technologies" },
  { value: "Synthetic Biology", label: "Synthetic Biology" },
  {
    value: "Digital and AI driven Technologies",
    label: "Digital and AI driven Technologies",
  },
];

export const applicationCategoryOptions = [
  { value: "Biomanufacturing", label: "Biomanufacturing" },
  { value: "Bioprocessing", label: "Bioprocessing" },
  { value: "Cell and Gene Therapy", label: "Cell and Gene Therapy" },
  {
    value: "Clinical Development and Trials",
    label: "Clinical Development and Trials",
  },
  {
    value: "Diagnostic and Biomarker Discovery",
    label: "Diagnostic and Biomarker Discovery",
  },
  { value: "Drug Delivery", label: "Drug Delivery" },
  {
    value: "Drug Discovery and Preclinical Development",
    label: "Drug Discovery and Preclinical Development",
  },
  { value: "Drug Manufacturing", label: "Drug Manufacturing" },
  {
    value: "Epigenetics and Genomic Editing",
    label: "Epigenetics and Genomic Editing",
  },
  {
    value: "Informatics & AI driven Data Analytics",
    label: "Informatics & AI driven Data Analytics",
  },
  { value: "Metabolomics", label: "Metabolomics" },
  { value: "Microbiome Analysis", label: "Microbiome Analysis" },
  {
    value: "Precision and Personalized Medicine",
    label: "Precision and Personalized Medicine",
  },
  {
    value: "Vaccine and Immunotherapy Development",
    label: "Vaccine and Immunotherapy Development",
  },
];

export const intellectualPropertyStatusChoices = [
  { value: "No IP filings", label: "No IP filings" },
  { value: "US Provisional filed", label: "US Provisional filed" },
  {
    value: "US Patent and / or PCT filed",
    label: "US Patent and / or PCT filed",
  },
  {
    value: "US Patent office action or PCT search report",
    label: "US Patent office action or PCT search report",
  },
  {
    value: "US Patent and / or PCT granted",
    label: "US Patent and / or PCT granted",
  },
  {
    value: "N/A – Product subject to trade secret",
    label: "N/A – Product subject to trade secret",
  },
];

export const freedomToOperateChoices = [
  { value: "No search done yet", label: "No search done yet" },
  { value: "Our own search looks good", label: "Our own search looks good" },
  {
    value: "Outside counsel has given a favorable opinion",
    label: "Outside counsel has given a favorable opinion",
  },
  {
    value: "Do not have freedom to operate",
    label: "Do not have freedom to operate",
  },
];

export const primaryRevenueModelOptions = [
  { value: "Equipment", label: "Equipment / Capital Sales" },
  { value: "Fee", label: "Fee for service" },
  { value: "License", label: "Licensing" },
  { value: "Reagent", label: "Reagent / Consumable" },
  { value: "SAAS", label: "SAAS / Subscription Based" },
  { value: "Transaction", label: "Transaction Fee / Royalty" },
];

export const keyMilestones = [
  "Feasibility Prototype / POC",
  "Clinical Prototype",
  "Pre-Clinical Study",
  "First-in-Human Study",
  "Pivotal / Approval Study",
  "Marketing Study (Validation)",
];

export const BTIProductDevelopmentStageOptions = [
  {
    value: ProductDevelopmentStage.concept,
    label: "Concept",
    secondary: "No Prototype - Idea Stage",
  },
  {
    value: ProductDevelopmentStage.prototypeDeveloped,
    label: "Prototype Developed",
  },
  {
    value: ProductDevelopmentStage.productDeveloped,
    label: "Product Developed",
  },
  {
    value: ProductDevelopmentStage.productDevelopedBetaTesting,
    label: "Product Developed - Beta Testing",
  },
  {
    value: ProductDevelopmentStage.marketReady,
    label: "Market Ready",
  },
  {
    value: ProductDevelopmentStage.payingCustomers,
    label: "Paying Customers",
  },
];

export enum DealTypes {
  AcceleratorIncubator = "Accelerator/Incubator",
  Angel = "Angel (individual)",
  Capitalization = "Capitalization",
  ConvertibleDebt = "Convertible Debt",
  DebtGeneral = "Debt - General",
  DebtPPP = "Debt - PPP",
  EarlyStageVC = "Early Stage VC",
  EquityCrowdfunding = "Equity Crowdfunding",
  Grant = "Grant",
  IPO = "IPO",
  LaterStageVC = "Later Stage VC",
  MergerAcquisition = "Merger/Acquisition",
  PEGrowthExpansion = "PE Growth/Expansion",
  PIPE = "PIPE",
  ProductCrowdfunding = "Product Crowdfunding",
  SecondaryTransactionPrivate = "Secondary Transaction - Private",
  SeedRound = "Seed Round",
  Other = "Other",
  ConvertibleNote = "Convertible Note",
  SAFE = "SAFE",
  PricedRound = "Priced Round",
  Bridge = "Bridge",
  Corporate = "Corporate",
  JointVenture = "Joint Venture",
  PublicInvestment2ndOffering = "Public Investment 2nd Offering",
  ReverseMerger = "Reverse Merger",
}

export const openRoundDealTypes = [
  DealTypes.ConvertibleNote,
  DealTypes.SAFE,
  DealTypes.PricedRound,
  DealTypes.Other,
];

export const validEquityDealTypes = [
  DealTypes.LaterStageVC,
  DealTypes.EarlyStageVC,
  DealTypes.Angel,
  DealTypes.ConvertibleDebt,
  DealTypes.DebtGeneral,
  DealTypes.SeedRound,
  DealTypes.Capitalization,
  DealTypes.AcceleratorIncubator,
  DealTypes.SecondaryTransactionPrivate,
  DealTypes.Bridge,
  DealTypes.Corporate,
  DealTypes.EquityCrowdfunding,
  DealTypes.IPO,
  DealTypes.JointVenture,
  DealTypes.PEGrowthExpansion,
  DealTypes.PIPE,
  DealTypes.ProductCrowdfunding,
  DealTypes.PublicInvestment2ndOffering,
  DealTypes.ReverseMerger,
  DealTypes.SecondaryTransactionPrivate,
];

export const dealRounds = [
  "Pre-Seed",
  "Seed",
  "Other",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
  "Series A1",
  "Series A2",
  "Series A3",
  "Series AA",
  "Series B1",
  "Series B2",
  "Series B3",
  "Series BB",
  "Series C1",
  "Series C2",
  "Series C3",
] as const;

export enum UserTags {
  mtiMentor2024 = "mtiMentor2024",
  mtiMentor2023 = "mtiMentor2023",
  mtiMentor2022 = "mtiMentor2022",
  btiMentor2024 = "btiMentor2024",
  btiMentor2023 = "btiMentor2023",
  btiMentor2022 = "btiMentor2022",
  apacMentor2024 = "apacMentor2024",
  apacMentor2023 = "apacMentor2023",
  apacMentor2022 = "apacMentor2022",
  mtiJudge2024 = "mtiJudge2024",
  mtiJudge2023 = "mtiJudge2023",
  mtiJudge2022 = "mtiJudge2022",
  btiJudge2024 = "btiJudge2024",
  btiJudge2023 = "btiJudge2023",
  btiJudge2022 = "btiJudge2022",
  apacJudge2024 = "apacJudge2024",
  apacJudge2023 = "apacJudge2023",
  apacJudge2022 = "apacJudge2022",
  mtiReviewer2024 = "mtiReviewer2024",
  mtiReviewer2023 = "mtiReviewer2023",
  mtiReviewer2022 = "mtiReviewer2022",
  btiReviewer2024 = "btiReviewer2024",
  btiReviewer2023 = "btiReviewer2023",
  btiReviewer2022 = "btiReviewer2022",
  apacReviewer2024 = "apacReviewer2024",
  apacReviewer2023 = "apacReviewer2023",
  apacReviewer2022 = "apacReviewer2022",
  mtiPartner2024 = "mtiPartner2024",
  mtiPartner2023 = "mtiPartner2023",
  mtiPartner2022 = "mtiPartner2022",
  btiPartner2024 = "btiPartner2024",
  btiPartner2023 = "btiPartner2023",
  btiPartner2022 = "btiPartner2022",
  apacPartner2024 = "apacPartner2024",
  apacPartner2023 = "apacPartner2023",
  apacPartner2022 = "apacPartner2022",
  "Pitch Judge" = "Pitch Judge",
  "Mentor" = "Mentor",
  "Partner" = "Partner",
  "Reviewer" = "Reviewer",
  clinicalTrials = "clinicalTrials",
  fundraising = "fundraising",
  operationsAndManagement = "operationsAndManagement",
  paymentAndReimbursement = "paymentAndReimbursement",
  productDevelopment = "productDevelopment",
  regulatory = "regulatory",
  sales = "sales",
  hiring = "hiring",
  finance = "finance",
  investor = "investor",
  marketing = "marketing",
  medicalDevice = "medicalDevice",
  diagnostic = "diagnostic",
  digitalHealth = "digitalHealth",
  bioTools = "bioTools",
}

export const AllTagOptions = [
  { value: UserTags.apacJudge2022, label: "APAC Judge 2022" },
  { value: UserTags.apacJudge2023, label: "APAC Judge 2023" },
  { value: UserTags.apacJudge2024, label: "APAC Judge 2024" },
  { value: UserTags.apacMentor2022, label: "APAC Mentor 2022" },
  { value: UserTags.apacMentor2023, label: "APAC Mentor 2023" },
  { value: UserTags.apacMentor2024, label: "APAC Mentor 2024" },
  { value: UserTags.apacPartner2022, label: "APAC Partner 2022" },
  { value: UserTags.apacPartner2023, label: "APAC Partner 2023" },
  { value: UserTags.apacPartner2024, label: "APAC Partner 2024" },
  { value: UserTags.apacReviewer2022, label: "APAC Reviewer 2022" },
  { value: UserTags.apacReviewer2023, label: "APAC Reviewer 2023" },
  { value: UserTags.apacReviewer2024, label: "APAC Reviewer 2024" },
  { value: UserTags.btiJudge2022, label: "BTI Judge 2022" },
  { value: UserTags.btiJudge2023, label: "BTI Judge 2023" },
  { value: UserTags.btiJudge2024, label: "BTI Judge 2024" },
  { value: UserTags.btiMentor2022, label: "BTI Mentor 2022" },
  { value: UserTags.btiMentor2023, label: "BTI Mentor 2023" },
  { value: UserTags.btiMentor2024, label: "BTI Mentor 2024" },
  { value: UserTags.btiPartner2022, label: "BTI Partner 2022" },
  { value: UserTags.btiPartner2023, label: "BTI Partner 2023" },
  { value: UserTags.btiPartner2024, label: "BTI Partner 2024" },
  { value: UserTags.btiReviewer2022, label: "BTI Reviewer 2022" },
  { value: UserTags.btiReviewer2023, label: "BTI Reviewer 2023" },
  { value: UserTags.btiReviewer2024, label: "BTI Reviewer 2024" },
  { value: UserTags.mtiJudge2022, label: "MTI Judge 2022" },
  { value: UserTags.mtiJudge2023, label: "MTI Judge 2023" },
  { value: UserTags.mtiJudge2024, label: "MTI Judge 2024" },
  { value: UserTags.mtiMentor2022, label: "MTI Mentor 2022" },
  { value: UserTags.mtiMentor2023, label: "MTI Mentor 2023" },
  { value: UserTags.mtiMentor2024, label: "MTI Mentor 2024" },
  { value: UserTags.mtiPartner2022, label: "MTI Partner 2022" },
  { value: UserTags.mtiPartner2023, label: "MTI Partner 2023" },
  { value: UserTags.mtiPartner2024, label: "MTI Partner 2024" },
  { value: UserTags.mtiReviewer2022, label: "MTI Reviewer 2022" },
  { value: UserTags.mtiReviewer2023, label: "MTI Reviewer 2023" },
  { value: UserTags.mtiReviewer2024, label: "MTI Reviewer 2024" },
  {
    value: UserTags.clinicalTrials,
    label: "Clinical Trials",
  },
  {
    value: UserTags.fundraising,
    label: "Fundraising",
  },
  {
    value: UserTags.operationsAndManagement,
    label: "Operations & Management",
  },
  {
    value: UserTags.paymentAndReimbursement,
    label: "Payment & Reimbursement",
  },
  {
    value: UserTags.productDevelopment,
    label: "Product Development",
  },
  {
    value: UserTags.regulatory,
    label: "Regulatory",
  },
  {
    value: UserTags.sales,
    label: "Sales",
  },
  {
    value: UserTags.hiring,
    label: "Hiring",
  },
  {
    value: UserTags.finance,
    label: "Finance",
  },
  {
    value: UserTags.investor,
    label: "Investor",
  },
  {
    value: UserTags.marketing,
    label: "Marketing",
  },
  {
    value: UserTags.medicalDevice,
    label: "Medical Device",
  },
  {
    value: UserTags.diagnostic,
    label: "Diagnostic",
  },
  {
    value: UserTags.digitalHealth,
    label: "Digital Health",
  },
  {
    value: UserTags.bioTools,
    label: "BioTools",
  },
];

export const expertiseOptions = [
  {
    value: UserTags.clinicalTrials,
    label: "Clinical Trials",
  },
  {
    value: UserTags.fundraising,
    label: "Fundraising",
  },
  {
    value: UserTags.operationsAndManagement,
    label: "Operations & Management",
  },
  {
    value: UserTags.paymentAndReimbursement,
    label: "Payment & Reimbursement",
  },
  {
    value: UserTags.productDevelopment,
    label: "Product Development",
  },
  {
    value: UserTags.regulatory,
    label: "Regulatory",
  },
  {
    value: UserTags.sales,
    label: "Sales",
  },
  {
    value: UserTags.hiring,
    label: "Hiring",
  },
  {
    value: UserTags.finance,
    label: "Finance",
  },
  {
    value: UserTags.investor,
    label: "Investor",
  },
  {
    value: UserTags.marketing,
    label: "Marketing",
  },
  {
    value: UserTags.medicalDevice,
    label: "Medical Device",
  },
  {
    value: UserTags.diagnostic,
    label: "Diagnostic",
  },
  {
    value: UserTags.digitalHealth,
    label: "Digital Health",
  },
  {
    value: UserTags.bioTools,
    label: "BioTools",
  },
];
export const ecosystemRoleOptions = [
  {
    value: UserTags["Pitch Judge"],
    label: "Pitch Judge",
  },
  {
    value: UserTags.Mentor,
    label: "Mentor",
  },
  {
    value: UserTags.Partner,
    label: "Partner",
  },
  {
    value: UserTags.Reviewer,
    label: "Reviewer",
  },
];
export interface CompanyAdmin {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface AccessRequest {
  id: number;
  userId: number;
  companyId: number;
  status: string;
  user: {
    firstName: string;
    lastName: string;
  };
  company: {
    name: string;
    admins: {
      firstName: string;
      lastName: string;
      email: string;
    }[];
  };
}

export interface DealView {
  viewedAt: string;
  dealId: number;
}

export interface Nomination {
  id: number;
  created: string;
  updated: string;
  deletedAt?: string;
  nominatorName: string;
  nominatorEmail: string;
  nominatorCompanyName: string;
  isAnonymousNomination: boolean;
  year: number;
  programs: string;
  nomineeCompanyName: string;
  nomineeContactName: string;
  nomineeContactTitle?: string;
  nomineeContactPhone?: string;
  nomineeContactEmail: string;
  relationshipToNominee?: string;
  reasonForNomination?: string;
  nominee: Company | null;
  nominator: User;
  emailSent: string;
}

export interface ProgramYearOption {
  value: string;
  label: string;
}

export const programYearOptions: ProgramYearOption[] = [
  { value: "All", label: "All" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
];

export enum Programs {
  "BioTools Innovator" = "BioTools Innovator",
  "MedTech Innovator" = "MedTech Innovator",
  "MedTech Asia Pacific Innovator" = "MedTech Asia Pacific Innovator",
  "VANGUARD Funding Solicitation" = "VANGUARD Funding Solicitation",
}

export const invalidFileText =
  "Invalid file type. Please upload in a common image format such as PNG, JPG, or JPEG.";

export interface IProgramApplicationSubmission
  extends ProgramApplicationSubmission {
  Company: string;
}

export type ApplicationReview = {
  id?: number;
  applicationId?: number;
  userId?: number;
  createdById?: number;
  created?: string; // ISO date string
  updated?: string; // ISO date string
  deletedAt?: string; // ISO date string or undefined

  previouslyAwareOfThisCompany?: boolean;
  unassigned?: boolean;
  yourCompanysAreaOfInterest?: boolean;
  areaOfExpertise?: string;
  shouldBeSelectedToPitch?: string;
  shouldBeSelectedToPitchRationale?: string;
  ratingOfTheOpportunity?: number;
  ratingOfTheProductInnovation?: string;
  additionalInformationNeeded?: string;
  howLikelyToRaiseFundsTheyAreSeeking?: string;
  anonymousFeedback?: string;
  internalNotes?: string;

  // Selection and admin-only fields
  mentorInterest?: string;
  acceleratorRecommendation?: string;
  selectNotes?: string;
  isAcceleratorFit?: boolean;
  isShowcaseFit?: boolean;
  deliberationNotes?: string;

  //Dev funding only fields
  ratingOfTechnicalFeasibility?: number;
  ratingOfTeamStrength?: number;
  ratingOfValueProposition?: number;
  ratingOfFundingLikelihood?: number;
  programAlignment?: string;
  programAlignmentExplanation?: string;
  fundingRealistic?: boolean;
  majorConcerns?: string;
  executionAttractiveness?: string;
  fundingRecommendation?: string;
  suggestedResources?: string;
  additionalEvaluationInfo?: string;
  hubReferral?: string;
  // Relationships
  application?: {
    id: number;
  };
  programApplicationSubmissionId?: number;
  programApplicationSubmission?: {
    id: number;
  };
  user?: User;
  reviewerCompanyId?: number;
  reviewerCompany?: {
    id: number;
    name: string; // Replace with actual company fields
  };
  ratingOfReimbursementStrategy?: string;
  reviewStatus?: string;
};

export enum ReviewerPermissionLevels {
  Reviewer = "Reviewer",
  FullReviewer = "FullReviewer",
  LimitedSelectionReviewer = "LimitedSelectionReviewer",
  SelectionReviewer = "SelectionReviewer",
  VotingReviewer = "VotingReviewer",
}

export enum ProgramApplicationTitles {
  "BioTools Innovator VANGUARD Funding Solicitation" = "BioTools Innovator VANGUARD Funding Solicitation",
  "MedTech Innovator APAC - Accelerator" = "MedTech Innovator APAC - Accelerator",
  "MedTech Innovator - Accelerator" = "MedTech Innovator - Accelerator",
  "BioTools Innovator - Accelerator" = "BioTools Innovator - Accelerator",
  "MedTech Innovator APAC - US Market Access" = "MedTech Innovator APAC - US Market Access",
}

export enum ProgramOptions {
  "MedTech Innovator - Accelerator" = "MedTech Innovator - Accelerator",
  "BioTools Innovator - Accelerator" = "BioTools Innovator - Accelerator",
  "MedTech Innovator APAC - Accelerator" = "MedTech Innovator APAC - Accelerator",
  "BioTools Innovator VANGUARD Funding Solicitation" = "BioTools Innovator VANGUARD Funding Solicitation",
}

export type ReviewerConfiguration = {
  [P in ProgramOptions]?: {
    permission: ReviewerPermissionLevels;
    assignmentsPerCycle: number;
    phrases?: string[];
    noPreference?: boolean;
    interestsConfirmed?: boolean;
  };
};

export const ProgramApplicationMappingIdToProgramOptions = {
  1: ProgramApplicationTitles[
    "BioTools Innovator VANGUARD Funding Solicitation"
  ],
  2: ProgramApplicationTitles["MedTech Innovator APAC - Accelerator"],
  3: ProgramApplicationTitles["MedTech Innovator - Accelerator"],
  4: ProgramApplicationTitles["BioTools Innovator - Accelerator"],
  5: ProgramApplicationTitles["MedTech Innovator APAC - US Market Access"],
};

export interface ListsMembershipDTO {
  userLists: ListMembershipDTO[];
  companyList?: ListMembershipDTO;
  userList?: ListMembershipDTO;
}
export interface ListMembershipDTO {
  id: number;
  name: string;
  programApplicationSubmissions: number[];
}

export interface Reviewer {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  assignedReviews: number;
  completedReviews: number;
  assignedReviews2025?: number;
  completedReviews2025?: number;
  reviewerConfiguration?: ReviewerConfiguration;
  tracks: string[];
  typeAbbreviation?: string;
  company?: {
    name: string;
    typeAbbreviation?: string;
  };
  focused?: boolean;
}

export interface GridState {
  id: number;
  label: string;
  columnState: any;
  filterState: any;
  filterKeyword?: string;
  filterListId?: number;
}

export interface ProgramApplicationSubmissionList {}
export interface List {
  id: number;
  name: string;
  programApplicationId: number;
}
export interface ProgramApplicationSubmissionList {
  list: List;
  programApplicationSubmissionId: number;
  exclude: boolean;
}
export interface PitchScore {
  id: number;
  user: User;
  judgeCompany: Company;
  created: string;
  wasPreviouslyAwareOfCompany: boolean;
  pitchNotes: string;
  anonymousFeedbackForStartup: string;
  technologyInnovationRating: number;
  overallLikelihoodOfSuccessRating: number;
  valueToPatientRating: number;
  technicalFeasbilityRating: number;
  strengthOfTeamRating: number;
  healthEconomicsRating: number;
  likelihoodOfFundingRating: number;
  majorConcernsOfCompany: string;
  attractiveCompanyAttributes: string;
  howWellDoesThisCompanyUnderstandTheirValueProposition: string;
  suggestedResourcesForCompany: string;
  companyWasCoachable: string;
  interestInFollowUpCall: string;
  additionalComments: string;
}

export enum ApplicationReviewStatus {
  NotStarted = "Not Started",
  Started = "Started",
  Completed = "Completed",
}
